@import 'assets/scss/reset';
@import 'assets/scss/bootstrap-grid';

body,
p,
a {
  line-height: 1.5;
  font-family: 'M PLUS 1p', sans-serif;
}

h1.header {
  position: relative;
  border: 1px solid white;
}
span.close-button {
  position: absolute;
  right: 1rem;
  width: 80px;
  height: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-image: url('/assets/img/icons/xrosss.png');
  background-size: contain;
  background-position: center;
  &:hover {
    cursor: pointer;
    background-image: url('/assets/img/icons/xrossselect.png');
  }
  @media (max-width: 576px) {
    width: 40px;
    height: 40px;
    right: 0;
  }
}

.slick-slider {
  display: flex;
  flex-direction: column;
}

.slick-dots {
  text-align: center;
  position: relative;
  order: -1;
  padding: 1rem;
  bottom: 0;

  li {
    text-align: center;
    margin: 0px 0.5rem;
    width: 1.5rem;
    height: 1.5rem;

    // background: #0f0f0d;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
      height: 100%;
      background: #302f2d;
      width: 100%;
      border-radius: 2rem;
      border-radius: 2rem;
      border: solid 3px #b8a480;
      &:hover {
        border-color: white;
        box-shadow: 0 0 1rem white;
      }
      &:before {
        font-size: 2rem;
        color: #302f2d;
        opacity: 1;
        display: none;
      }
    }
    @media (max-width: 576px) {
      width: 1rem;
      height: 1rem;
      button {
        height: 0.75rem;
        width: 0.75rem;
      }
    }
  }
}

.slick-arrow {
  @media (max-width: 576px) {
    width: 4rem !important;
    height: 4rem !important;
    &.slick-prev {
      left: -3.5rem !important;
    }
    &.slick-next {
      right: -3.5rem !important;
    }
  }
}

.slider-col {
  @media (max-width: 576px) {
    padding: 0 !important;
  }
}

.slick-dots li.slick-active button {
  background: #ffb82c;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes swipeDownInfinite {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes swipeUp {
  0% {
    margin-top: 0;
  }
  100% {
    margin-top: -100vh;
  }
}

@keyframes slideRight {
  0% {
    background-position-x: 0vw;
    // background-repeat: repeat;
  }

  100% {
    background-position-x: calc(
      900px + (1920 - 900) * ((100vw - 320px) / (1920 - 320))
    );
    // background-repeat: repeat;
  }
}

@keyframes beastBreathing {
  0% {
    transform: scaleX(1) scaleY(1);
    transform-origin: bottom;
  }

  20% {
    transform: scaleX(1.06) scaleY(0.99);
    transform-origin: bottom;
  }

  40% {
    transform: scaleX(1) scaleY(1);
    transform-origin: bottom;
  }
}

// x=1, y=1, do: x=1.06, y=0.97

@media (max-width: 1366px) {
  html {
    font-size: 12px;
  }
}

@media (max-width: 1536px) {
  html {
    font-size: 14px;
  }
}

// mobile version

// FONTS
section .row {
  @media (max-width: 576px) {
    h1 {
      font-size: 1.65rem;
    }
    h2 {
      font-size: 1.5rem !important;
    }
  }
}

// HEADER
#main-header {
  a {
    span.btnIcon:hover {
      + span.btnLabel {
        text-shadow: 0 0 2rem white;
      }
    }
  }
}

#mobile-header {
  display: none;
}

@media (max-width: 1184px) {
  #main-header {
    display: none;
  }
  #mobile-header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    background: none;
    padding: 0;
    height: 110px;
    top: 0;
    .container-fluid {
      padding: 1rem;
      background: radial-gradient(
        50% 8647.03% at 50% 50%,
        rgba(0, 0, 0, 0.5) 37.97%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    .bottom-menu {
      position: absolute;
      bottom: calc(-100vh + 110px);
      .button-column a {
        bottom: 0;

        span.top {
          font-size: 1.5rem;
        }
        span.bottom {
          font-size: 1rem;
        }
      }
    }
  }
}
@media (max-width: 600px) {
  #mobile-header {
    height: 100px;
    .container-fluid {
      padding: 0.5rem;
      &.bottom-menu {
        bottom: calc(-100vh + 100px);
        height: 100%;
        .button-column {
          width: 230px;
          height: 144px;
          display: block;
          min-width: 230px;
          a {
            width: calc(22.1875rem * 0.75);
            height: calc(13.75rem * 0.75);
          }
        }
      }
      .row {
        width: 100%;
        margin: 0;
        .col {
          padding-right: 10px;
          padding-left: 10px;
          .iconBtn {
            .btnIcon {
              width: 4rem;
              height: 3rem;
            }
            .btnLabel {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  #mobile-header {
    .container-fluid {
      &.bottom-menu {
        .button-column {
          width: calc(22.1875rem * 0.5);
          height: calc(13.75rem * 0.5);
          display: block;
          min-width: calc(22.1875rem * 0.5);
          a {
            width: calc(22.1875rem * 0.5);
            height: calc(13.75rem * 0.5);
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 2.5rem;
            bottom: 1rem;
            span.top {
              font-size: 18px;
            }
            span.bottom {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 405px) {
  #mobile-header .container-fluid {
    padding: 5px;
    .row .col {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
}

// NEWS
section.news-section {
  height: 100vh;
}
//   @media (max-width: 1184px) {
//     .container {
//       height: calc(100% - 320px);
//       justify-content: flex-start;
//     }
//   }

//   @media (max-height: 873px) {
//     .container {
//       .content-wrapper {
//         overflow: auto;
//         p {
//           height: inherit;
//         }
//       }
//       .with-image {
//         height: 17vh;
//       }
//     }
//   }

//   @media (max-width: 600px) {
//     .container {
//       height: calc(100% - 220px);
//       .content-wrapper {
//         height: 25vh;
//       }
//     }
//   }
// }

// COMMUNITY
section.community-section {
  @media (max-width: 990px) {
    .iconCol-0-2-15 a {
      display: flex;
      justify-content: center;
    }
  }
  @media (max-width: 576px) {
    .iconCol-0-2-15 a span {
      width: 60px;
      height: 60px;
      margin-top: 1rem;
      background-size: contain;
    }
  }
}

// mobile fog
.fog,
.fog-bottom {
  background-size: calc(900px + (1920 - 900) * ((100vw - 320px) / (1920 - 320)))
    100% !important;
}

// MEDIA
.single-slide .video-wrapper {
  display: flex;
  justify-content: center;
  height: 50vh;
  align-items: center;
  iframe {
    width: 100%;
    height: 100%;
  }
}

.media-slider {
  .slick-slides,
  .single-slide {
    overflow-y: hidden;
    padding-right: 1rem;
  }

  .single-slide .image-wrapper {
    height: 100%;
    img {
      height: 100%;
      object-fit: contain;
    }
  }

  @media (max-width: 768px) {
    .single-slide .video-wrapper iframe {
      max-width: 80vw;
      height: unset;
    }
  }

  @media (max-width: 576px) {
    .single-slide {
      height: 50vh;
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
    iframe {
      max-width: 80vw;
    }
  }
}

//content p
p {
  a {
    color: inherit;
  }
}

//Slick text support

.slick-slider {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.slick-list.draggable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.single-slide {
  .image-wrapper {
    img {
      &:hover {
        cursor: zoom-in;
      }
    }
  }
}

// dots and silder  sizing on mobile and scrool to fit conent on every screen size
.slick-slides,
.single-slide {
  height: calc(100vh - 420px);
  overflow-y: auto;
  padding-right: 1rem;
}

@media (max-width: 576px) {
  .container {
    padding-bottom: 0;
  }
  .slick-dots {
    padding: 0 1rem 1rem;
  }
  .header-0-2-11 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.25rem;
  }
  .slick-slide {
    height: calc(100vh - 305px);
    // overflow-y: scroll;
  }
}

// scrollbar
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #bdae8c;

  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cc985e;
  border-radius: 10px;
}

//Image_Component
.gallery-overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  img {
    height: 80%;
    width: 80%;
    cursor: zoom-out;
    object-fit: contain;
  }
}

.main-mobile-bg {
  width: 100%;
  height: 100%;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.beast-layer {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  .beast {
    width: 206px;
    height: 269px;
    background-size: contain;
    position: relative;
    top: unset;
    right: unset;
    left: unset;
  }
}

@media (max-width: 576px) {
  .mobile-blur {
    background: rgba(0, 0, 0, 0.5) !important;
  }
}
